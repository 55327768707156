<template>
    <div class="toast toast-center z-99999 [@supports(color:oklch(0_0_0))]:hidden">
        <div class="alert bg-black grid-cols-[auto] mb-2 py-2 text-white text-xl">
            <span>
                <a class="link" rel="nofollow, noreferrer" target="_blank" href="https://mobile.baidu.com/item?docid=5007681036&f0=search_searchContent%400_appBaseNormal%400&advitem=source%2Bnatural%40pos%2B1%40bid%2B0%40ctr%2B0.000000%40trans%2B%40keyword%2Bedge%40app_rcv%2B"> 请使用现代浏览器(chrome firefox等)</a>
            </span>
        </div>
        <div class="alert bg-black grid-cols-[auto] mb-2 py-2 text-white text-xl">
            <span>
                <a class="link" rel="nofollow, noreferrer" target="_blank" href="https://mobile.baidu.com/item?docid=5007681036&f0=search_searchContent%400_appBaseNormal%400&advitem=source%2Bnatural%40pos%2B1%40bid%2B0%40ctr%2B0.000000%40trans%2B%40keyword%2Bedge%40app_rcv%2B"> 获得更好的浏览效果 </a>
            </span>
        </div>
    </div>
</template>
<script setup lang="ts"></script>
